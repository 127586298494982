<template>
  <div class="observer"></div>
</template>

<script>
export default {
  name: "observer-component",
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
    //   console.log("mounted");
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    });
    this.observer.observe(this.$el);
  },
};
</script>